import React, { useState } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import compact from "lodash/compact"
import uniq from "lodash/uniq"

import Layout from "src/components/new/Layout"
import Header from "src/components/new/Header"
import PartnersList from "src/components/partners/List"
import PartnersFilter from "src/components/partners/Filter"

import "./partners.scss"

const PartnersPage = ({data: {partners}, location}) => {

  const [ownerFilter, setOwner] = useState(null)
  const [stageFilter, setStage] = useState(null)

  const partnerList = [...partners.edges]

  const owners = uniq(compact(partnerList.map(partner => partner.node.frontmatter.partner.owner))).sort()
  const stages = ["research", "validate", "create", "scale"]

  const getFilteredPartners = list => {
    let filteredPartners = list
  
    if(ownerFilter) {
      filteredPartners = filteredPartners.filter(partner => 
        partner.node.frontmatter.partner.owner === ownerFilter
      )
    }
  
    if(stageFilter) {
      filteredPartners = filteredPartners.filter(partner => 
        partner.node.frontmatter.partner.stages?.includes(stageFilter)
      )
    }
    return filteredPartners
  }

  const filteredList = getFilteredPartners(partnerList)
  
  return (
    <Layout location={location} meta={{
      title: "Our partners",
      description: "Key partners, integrations, and tools we work with.",
    }}>
      <div className="partners-page">
        <Header>
          <h1>Key partners, integrations, and tools we work with.</h1>
        </Header>

        <div className="partners-filter">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <PartnersFilter 
                  ownersList={owners}  
                  stagesList={stages} 
                  selectedOwner={ownerFilter} 
                  setOwner={setOwner}
                  selectedStage={stageFilter} 
                  setStage={setStage}
                />
              </div>
            </div>
          </div>
        </div>

        <section>
          <PartnersList list={filteredList} />
        </section>
      </div>
    </Layout>
  )
}

PartnersPage.defaultProps = {
  data: {
    page: {
      partners: {edges: []},
    },
  },
}

PartnersPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      partners: PropTypes.shape({edges: PropTypes.arrayOf(PropTypes.object)}),
    }),
  }),
}

export const pageQuery = graphql`
  query {
    partners: allMarkdownRemark(
      filter: { 
        frontmatter: { 
          meta: {template: {in: ["partner"]}, indexed: {ne: false}},
        }
      }
      sort: { fields: frontmatter___partner___name, order: ASC }
    ) {
      edges {
        node {
          ...PartnerFragment
        }
      }
    }
  }
`

export default PartnersPage
