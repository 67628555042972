import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import { capitalize } from "src/utils/strings"

import "./List.scss"

const PartnerList = ({ list }) => {

  const chunkPartners = partners => {
  
    const chunked = []
    let index = 0
    while(index < partners.length) {
      chunked.push(partners.slice(index, index+3))
      index += 3
    }
    return chunked
  }

  if(!list) return null

  const chunked = chunkPartners(list)

  if(chunked.length === 0) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-1">
            <p>
              <em>No partners match this filter.</em>
            </p>
          </div>
        </div>
      </div>
      )
  }

  return (
    <div className="partners-list">
      {chunked.map((threePartners, key) => {
        const emptyBlocks = 3 - threePartners.length
        const unique = threePartners[0].node.frontmatter.partner.name
        return (
          <div className="partners-list-row" key={unique}>
            <div className="side-content" />
            {threePartners.map(partner => {
              const data = partner.node.frontmatter.partner
              const { name, description, link , owner, stages, discount} = data
              const external = !link || link.match(/^http/)

              const content = 
              <>
                <div className="top">
                  <div>
                    <GatsbyImage
                      image={data.logo.childImageSharp.gatsbyImageData}
                      alt={name}
                      className="logo" />
                    <h5>{name}</h5>
                    <p>{description}</p>
                  </div>
                  {discount && <span className="discount" title="Partner discount available">$</span>}
                </div>
                <div className="tags">
                  {stages &&
                    <>{stages.map(stage => <span className="stages" key={stage}>{capitalize(stage)}</span>)}</>
                  }
                  <span className="owner">{owner}</span>
                </div>
              </>

              return (
                <React.Fragment key={name}>
                  {external
                  ? <a
                      href={`${link}?utm_source=vaporware&utm_medium=website&utm_campaign=partner`}
                      className="content"
                      target="_blank"
                    >
                      {content}
                    </a>
                  : <Link 
                      to={link}
                      className="content"
                    >
                      {content}
                    </Link>
                  }
                </React.Fragment>
              );
            })}
            {[...Array(emptyBlocks).keys()].map((k, i) => (
              <div key={i} className="content empty" />
            ))}
            <div className="side-content" />
          </div>
        );
      })}
    </div>
  );
}

PartnerList.defaultProps = {
  partners: []
}

PartnerList.propTypes = {
  partners: PropTypes.arrayOf(PropTypes.object),
}

export default PartnerList
