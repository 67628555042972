import React from "react"
import { capitalize } from "src/utils/strings"
import "./Filter.scss"

const PartnerFilter = ({ 
  ownersList, 
  stagesList, 
  selectedOwner, 
  setOwner,
  selectedStage,
  setStage
}) => {

  const handleOwnerClick = owner => {
    const value = selectedOwner === owner ? null : owner
    setOwner(value)
  }

  const handleStageClick = stage => {
    const value = selectedStage === stage ? null : stage
    setStage(value)
  }

  return (
    <>
      <h3>Stage Introduced</h3>
      <ul className="filter-list stages">
        {stagesList.map(stage => (
          <li 
            key={stage}
            className={selectedStage === stage ? "selected" : ""}
            onClick={() => handleStageClick(stage)}
          >
            {capitalize(stage)}
          </li>
        ))}
      </ul>

      <h3>Functional Department</h3>
      <ul className="filter-list owners">
        {ownersList.map(owner => (
          <li 
            key={owner}
            className={selectedOwner === owner ? "selected" : ""} 
            onClick={() => handleOwnerClick(owner)}
          >
            {capitalize(owner)}
          </li>
        ))}
      </ul>
    </>
  )
}

export default PartnerFilter